<template>
  <CFooter :fixed="false">
    <div>
      <a style="text-decoration:none" target="_blank" href="https://www.resolution.studio/"><span style="color:#0dcaf0">Resolution</span> Design</a>
      <span class="ml-1">&copy; {{new Date().getFullYear()}}</span>
    </div>
    <div class="mfs-auto">
      <span class="mr-1" target="_blank">Powered by</span>
      <a style="text-decoration:none" href="https://www.resolution.studio/"><span style="color:#0dcaf0">Resolution</span> Design</a>
    </div>
  </CFooter>
</template>

<script>
export default {
  name: 'TheFooter'
}
</script>
