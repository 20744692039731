export default [
  {
    _name: 'CSidebarNav',
    _children: [
      {
        _name: 'CSidebarNavItem',
        name: 'Dashboard',
        to: '/home/dashboard',
        icon: 'cil-speedometer',
        badge: {
          color: 'primary',
          text: 'NEW'
        }
      },
      {
        _name: 'CSidebarNavItem',
        name: 'News',
        to:"/home/news",
        icon: 'cil-newspaper',
        
        
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Tags',
        to:'/home/view_tag',
        icon: 'cil-tags',
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Areas of Expertise' ,
        icon: "cil-calendar",
        to: "/home/areas_of_expertise"
      },
      {
        _name: 'CSidebarNavItem',
        icon:'cil-commentBubble',
        name:'Contact Us Information',
        to:'/home/contactus'
      },
            {
        _name: 'CSidebarNavItem',
        name: 'Subscription',
        to: '/home/subscription',
        icon: 'cil-calculator',
       
      },
      {
        _name: 'CSidebarNavItem',
        name: 'People',
        to: '/home/people',
        icon: 'cil-user-follow',
       
      },
      
      {
        _name: 'CSidebarNavItem',
        name: 'Partners',
        to: '/home/partners',
        icon: 'cil-user-follow',
       
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Intervention',
        to: '/home/intervention',
        icon: 'cil-task',
       
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Sector',
        to: '/home/sector',
        icon: 'cil-apps-settings',
       
      },
      {
        _name: 'CSidebarNavItem',
        name: 'Project Component',
        to: '/home/component',
        icon: 'cil-object-group',
       
      },

      
      {_name: 'CSidebarNavDropdown',
        name: 'Project',
        route: '/base',
        icon: 'cil-folder-open',
        items: [
          {
            name: 'Project',
            to: '/home/project',
          },
          {
            name: 'Update data',
            to: '/home/project/change_acomplishment',
          }

        ]},
        {
          _name: 'CSidebarNavItem',
          name: 'Knowledge',
          to: '/home/knowledge',
          icon: 'cil-tv',
         
        },

        {_name: 'CSidebarNavDropdown',
        name: 'Careers',
        route: '/base',
        icon: 'cil-briefcase',
        items: [
          {
            name: 'Careers',
            to: '/home/careers',
          },
          {
            name: 'Companies',
            to: '/home/careers/comapanies',
          },
          {
            name: 'Job Seekers',
            to: '/home/careers/job_seekers/false',
          },
          {
            name: 'Jobs',
            to: '/home/careers/jobs',
          },

        ]},

     
    ]
  }
]