<template>
  <CDropdown
    inNav
    class="c-header-nav-items"
    placement="bottom-end"
    add-menu-classes="pt-0"
  >
    <template #toggler>
      <CHeaderNavLink>
        <div class="c-avatar">
          Admin
        </div>
      </CHeaderNavLink>
    </template>

    
  
    <CDropdownItem>
      <span @click="signout">
        <CIcon name="cil-lock-locked"  /> Logout
      </span>
      
    </CDropdownItem>
  </CDropdown>
</template>

<script>
import { logout } from '../assets/js/service'
export default {
  name: 'TheHeaderDropdownAccnt',
  data () {
    return { 
      itemsCount: 42
    }
  },
  methods:{
    signout(){
      let token = localStorage.getItem('token');
      logout(token).then(resp=>{
        this.$router.replace("/login");
      })
    }
  }
}
</script>

<style scoped>
  .c-icon {
    margin-right: 0.3rem;
  }
</style>