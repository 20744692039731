<template>
  <CHeader fixed with-subheader light>
    <CToggler
      in-header
      class="ml-3 d-lg-none"
      @click="$store.commit('toggleSidebarMobile')"
    />
    <CToggler
      in-header
      class="ml-3 d-md-down-none"
      @click="$store.commit('toggleSidebarDesktop')"
    />
    <CHeaderBrand class="mx-auto d-lg-none" to="/" style="width=20%" >
        <img src="@/assets/img/RS_ (2).png" class="img-fluid" alt="" width="200px" />
    </CHeaderBrand>
    <CHeaderNav class="d-md-down-none mr-auto">
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/dashboard">
          Dashboard
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/home/sector" exact>
          Sector
        </CHeaderNavLink>
      </CHeaderNavItem>
      <CHeaderNavItem class="px-3">
        <CHeaderNavLink to="/home/news" exact>
          News
        </CHeaderNavLink>
      </CHeaderNavItem>
    </CHeaderNav>
    <CHeaderNav class="mr-4">
      <b-form-checkbox-group
      v-model="dashboard"
      :options="options"
      @change="changeDashboardStatus"
      class="mb-3"
      value-field="item"
      text-field="name"
      disabled-field="notEnabled"
    ></b-form-checkbox-group>
        
      
      <TheHeaderDropdownAccnt/>
    </CHeaderNav>
    <CSubheader class="px-3">
      <CBreadcrumbRouter class="border-0 mb-0"/>
    </CSubheader>
  </CHeader>
</template>

<script>
import { getDatas, getFieldById, pathField } from '../assets/js/service';
import TheHeaderDropdownAccnt from './TheHeaderDropdownAccnt'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderDropdownAccnt
  },
  data() {
    return {
      dashboard:false,
      switch:true,
      options:[  
          { item: true, name: 'Show Dashboard', notEnabled: false },
          ]
      
    }
  },
  mounted() {
    getFieldById('configs','63480c6d924a1673b1a904b8',true,false).then(resp=>{
      this.dashboard = resp.data.dashboard;
    })
  },
  methods: {
    changeDashboardStatus(){
      this.options[0].notEnabled =true;
      pathField('configs','63480c6d924a1673b1a904b8',{dashboard:this.dashboard},true).then(resp=>{
        this.options[0].notEnabled =false;
      })
    }
  },
}
</script>
